<template>
  <div class="row feed boxw mb-1">
    <div class="box">
      <div class="pd-10 pr">
        <div style="display: flex;justify-content: space-between;margin-bottom: 6px;padding-bottom:8px;border-bottom: 1px solid #887c7c;">
          <div><router-link to="/messages"><i class="fas fa-chevron-left pd-10"></i></router-link></div>
          <div>
            <p style="font-size: 1.2rem;margin: 0;margin-top: 4px;color:#4c4c54;font-weight:600">
            <a class="text-danger m-r-5" @click="delMessage"><i class="fa fa-trash"></i></a> <router-link :to="'/profile/' + $route.params.id + '/' + $route.params.user">{{ $route.params.user }}</router-link></p>
          </div>
          <div class=" mt-1 mb-1">
            <div class="profile-image profile-image-small">
              <router-link :to="'/profile/' + $route.params.id + '/' + $route.params.user"><img v-lazy="'/users/' + Math.floor($route.params.id /1000) + '/' + $route.params.user + '/imgp.jpg'" alt=""></router-link>
            </div>
          </div>
        </div>

        <div id="messages" class="chat-messages">
          <div :class="{'msg-right': $route.params.user !== dt.from_username, 'msg-left': $route.params.user === dt.from_username}" v-for="(dt, index) in msg" :key="index">{{ dt.message }}
            <span class="horario" v-data1>{{ dt.created_at }}</span>
            <!--<div class="chatinfo text-right"></div>-->
          </div>
        </div>
        <div class="input-group mt-10" style="padding-top: 10px;">
          <input v-model="message" @keyup.enter="sendMsg" type="search" placeholder="" aria-describedby="button-addon5" class="form-control">
          <div class="input-group-append">
            <button id="button-addon5" type="submit" class="btn btn-detail" @click="sendMsg"><i class="fa fa-paper-plane" aria-hidden="true"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
<!--
  <div class="row feed boxw mb-1 " >
    <div class=" box pb-2 ">
      <div style="padding:10px;position:relative">
        <h3>Mensagens</h3>
        <div class="input-group col-12 search-bar">
          <i class="fa fa-search" aria-hidden="true"></i>
          <input type="search" placeholder="Search..." aria-describedby="button-addon5" style="" class="form-control pl-3" v-model="search">
        </div>
        <div class="mt-3 mb-4" style="display:flex;overflow-y:hidden">
          <div class="avatar-list mt-1 mb-1" v-for="(dt, index) in res" :key="index">
            <router-link :to="'/message/' + (dt.from_username === user.username ? dt.to + '/' + dt.to_username : dt.from + '/' + dt.from_username)" v-show="!search.length || search.length && (dt.from_username === user.username ? dt.to_username : dt.from_username).toLowerCase().search(search.toLowerCase()) !== -1">
              <div class="profile-image">
              <img v-lazy="'https://images.unsplash.com/photo-1513721032312-6a18a42c8763?w=152&amp;h=152&amp;fit=crop&amp;crop=faces'" alt="">
              <i class="fas fa-circle "></i>
              </div>
              <div>{{ dt.from_username === user.username ? dt.to_username : dt.from_username }}</div>
            </router-link>
          </div>
        </div>

        <div class="message-list">
          <div class="chat">
            <div class="chat-header clearfix">
              <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/chat_avatar_01_green.jpg" alt="" />
              <div class="chat-about">
                <div class="chat-with">{{ $route.params.user }}</div>
                <div class="chat-num-messages">{{ msg.length}} mensagens</div>
              </div>
              <i class="fa fa-star"></i>
              <a @click="delMessage"><i class="fa fa-trash"></i></a>
            </div>
            <div class="chat-history">
              <ul>
                <li :class="{'clearfix': $route.params.user !== dt.from_username, 'message-data': $route.params.user === dt.from_username}" v-for="(dt, index) in msg" :key="index">
                  <div :class="{'message other-message': 1 ,'float-right': $route.params.user !== dt.from_username}" v-html="dt.message"></div>
                </li>
              </ul>
            </div>
            <div class="chat-message clearfix">
              <textarea name="message-to-send" id="message-to-send" placeholder="message" rows="3" v-model="message" @keyup.enter="sendMsg"></textarea>
              <button @click="sendMsg">Send</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
-->
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { messagesOpen, messages, message, messagesDelete } from './../resources/fn'
import { success, error } from '../resources/functions'

export default {
  name: 'post',
  data () {
    return {
      res: [],
      msg: [],
      search: '',
      message: ''
    }
  },
  beforeMount () {
    this.SET_LOAD(true)
    messagesOpen(this.axios, this.$route.params.id, this.$route.params.user, {}).then((res) => {
      this.msg = res.data
      this.SET_LOAD()
    }, (res) => {
      this.SET_LOAD()
    })
    messages(this.axios, {}).then((res) => {
      this.res = res.data
    }, (res) => {})
  },
  computed: {
    ...mapState({
      user: 'user'
    })
  },
  methods: {
    ...mapMutations([
      'SET_LOAD'
    ]),
    sendMsg () {
      if (this.message.length) {
        this.SET_LOAD(true)
        message(this.axios, this.$route.params.id, this.$route.params.user, { message: this.message }).then((res) => {
          this.message = ''
          this.msg.push(res.data)
          success(this, 'mensagem enviado com sucesso')
          this.SET_LOAD()
        }, (res) => {
          error(this, 'nao foi possivel enviar mensagem')
          this.SET_LOAD()
        })
      } else {
        error(this, 'mensagem nao pode ser vazio')
      }
    },
    delMessage () {
      messagesDelete(this.axios, this.$route.params.id, this.$route.params.user).then((res) => {
        success(this, 'conversa apagada com sucesso')
        this.SET_LOAD()
        this.$router.replace('/messages')
      }, (res) => {
        error(this, 'nao foi possivel apagar conversa')
        this.SET_LOAD()
      })
    }
  }
}
</script>

<style>
.chat-messages {
    overflow-y: scroll;
  height: calc(100vh - 182px);
    background: #fff;
  font-size:14px;
}
</style>
